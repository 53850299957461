<template>
  <section v-if="queueData" v-loading="isLoading" class="queue">
    <TerminalBackButton
      class="queue__button"
      @clicked="$router.push({ name: 'terminal-carNumber' })"
    />

    <div class="queue__title">Текущая очередь</div>

    <div class="queue__total">
      <div v-for="item in queueHeaderFields" :key="item.name">
        <span class="queue__total-label">{{ item.label }}</span>
        <span class="queue__total-value">&nbsp;{{ queueData[item.name] }}</span>
      </div>
    </div>

    <el-row class="queue__headers">
      <el-col
        v-for="title in queueTableFields"
        :key="title.name"
        :span="title.width"
        class="queue__headers-item"
      >
        {{ title.label }}
      </el-col>
    </el-row>

    <el-row
      v-for="timeslot in queueData.list"
      :key="timeslot.id"
      :class="[
        'queue__timeslot',
        timeslot.truck_status,
        { current: isCurrent(timeslot) },
      ]"
    >
      <el-col
        v-for="title in queueTableFields"
        :key="title.name"
        :span="title.width"
        :class="['queue__timeslot-item', title.name]"
      >
        <TableField :field="title" :timeslot="timeslot" />
      </el-col>
    </el-row>

    <el-pagination
      background
      :hide-on-single-page="true"
      :page-count="pagination.pageCount"
      :pager-count="5"
      :current-page.sync="pagination.page"
      layout="prev, pager, next"
      class="custom-pagination"
      @current-change="changePage"
    />
  </section>
</template>

<script>
import {
  queueHeaderFields,
  queueTableFields,
} from '@/views/terminalUI/currentQueue/data/fields'
import TableField from '@/views/terminalUI/currentQueue/components/TableField.vue'
import TerminalBackButton from '@/views/terminalUI/components/UI/buttons/TerminalBackButton.vue'

import { GET_PROFILE_FROM_STATE } from '@/views/profile/store/actions'
import { mapGetters } from 'vuex'

import Api from '@/api'

// фиксированное количество записей на странице, помещающихся на физическом Киоске
const PAGE_SIZE = 9

export default {
  name: 'CurrentQueue',
  components: {
    TableField,
    TerminalBackButton,
  },
  data() {
    return {
      isLoading: false,
      queueData: null,
      pagination: {
        page: 1,
        pageCount: 1,
        perPage: PAGE_SIZE,
      },
      timer_id: null,
      queueHeaderFields,
      queueTableFields,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: GET_PROFILE_FROM_STATE,
    }),
    terminalIds() {
      return this.currentUser?.unloads?.map(item => item.id)
    },
  },
  mounted() {
    const params = {
      'per-page': this.pagination.perPage,
      unload_id: this.terminalIds,
    }

    // если запрос от конкретной машины - передаём госномер (и в ответе получаем страницу с этой машиной)
    // иначе просто запрашивает первую страницу
    this.$route.params.plate_truck
      ? (params.plate_truck = this.$route.params.plate_truck)
      : (params.page = 1)

    this.fetchQuery(params)
  },
  beforeDestroy() {
    clearTimeout(this.timerId)
  },
  methods: {
    async fetchQuery(params) {
      this.isLoading = true

      try {
        const { data } = await Api.terminal.getTerminalQueuePage(params)

        this.queueData = data
        this.pagination.page = data.pagination.page
        this.pagination.perPage = data.pagination.per_page
        this.pagination.pageCount = data.pagination.page_count
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
        this.timerId = setTimeout(
          () => this.$router.push({ name: 'terminal-carNumber' }),
          120000,
        )
      }
    },
    changePage(page) {
      const params = {
        page,
        'per-page': this.pagination.perPage,
        unload_id: this.terminalIds,
      }

      clearTimeout(this.timerId)
      this.fetchQuery(params)
    },
    isCurrent(timeslot) {
      return (
        this.$route.params.plate_truck &&
        this.$route.params.plate_truck.toUpperCase() ===
          timeslot.plate_truck.toUpperCase()
      )
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/terminalUI/terminalUI.sass'
@import "@/assets/styles/terminalUI/custom-pagination"

.queue
  padding: 34px 40px
  &__button
    margin-bottom: 14px
  &__title
    margin-left: 100px
    margin-bottom: 16px
    font-family: $fontPrimary
    font-size: 48px
    font-weight: 500
    line-height: 56px
    color: $mainBlack
  &__total
    display: flex
    gap: 60px
    margin-left: 100px
    margin-bottom: 32px
    font-size: 24px
    font-weight: 500
    line-height: 30px
    *
      font-family: $fontPrimary
    &-label
      color: $greyText
    &-value
      color: $mainBlack
  &__headers
    padding: 0 14px
    background: $backgroundGray
    border-radius: 20px 20px 0 0
    &-item
      display: flex
      align-items: center
      height: 50px
      padding: 0 10px
      font-family: $fontSecondary
      font-size: 14px
      font-weight: 700
      line-height: 18px
      color: $greyText

  &__timeslot
    height: 60px
    padding: 0 14px
    border-bottom: 1px solid $color-second-background
    &:last-of-type
      border-bottom: none
      border-radius: 0 0 20px 20px
    &.parked_far
      background: $color-white
    &.parked_close
      background: $color-beige
    &.current
      background: #EBF8F9

    &-item
      display: flex
      align-items: center
      height: 100%
      padding: 0 16px
      font-size: 16px
      line-height: 18px
      color: $mainBlack
      & *, ::v-deep *
        font-family: $fontSecondary
      &.queue_position
        font-weight: 700
      &.plate_truck
        font-size: 20px
        font-weight: 700
      .truck_status
        display: flex
        align-items: center
        gap: 8px
        width: 100%
        height: 32px
        padding: 0 10px
        line-height: 17px
        border-radius: 54px
        background: green
        &.parked_close
          background: #FAECD8
        &.parked_far
          background: #E7F5F2
        ::v-deep .el-icon-success
          font-size: 20px
          color: #00AE88
        ::v-deep .el-icon-warning
          font-size: 20px
          color: #F37B38

  .custom-pagination
    margin-top: 8px
</style>
