export const queueHeaderFields = [
  { name: 'buffer_count', label: 'В буфере: ' },
  { name: 'parking_count', label: 'На парковке:' },
  { name: 'on_the_way_today', label: 'В пути на сегодня:' },
]

export const queueTableFields = [
  { name: 'queue_position', label: '№', width: 2, function: 'createPosition' },
  {
    name: 'plate_truck',
    label: 'Номер авто',
    width: 3,
    function: 'createDefault',
  },
  {
    name: 'culture_name',
    label: 'Культура',
    width: 6,
    function: 'createDefault',
  },
  {
    name: 'timeslot_type',
    label: 'Тип таймслота',
    width: 3,
    function: 'createDefault',
  },
  {
    name: 'parking_time',
    label: 'Приезд на парковку',
    width: 3,
    function: 'createTime',
  },
  { name: 'truck_status', label: 'Статус', width: 4, function: 'createStatus' },
  {
    name: 'buffer_time',
    label: 'Заезд в буфер',
    width: 3,
    function: 'createTime',
  },
]
