<script>
import { GET_TIME } from '@/constants/date'
import { getFormattedTzDate } from '@/core'

export default {
  name: 'TableField',
  props: {
    timeslot: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    createDefault(createElement) {
      return createElement('span', { class: this.field.name }, [
        this.timeslot[this.field.name],
      ])
    },
    createPosition(createElement) {
      return createElement('span', { class: this.field.name }, [
        this.timeslot[this.field.name] || '-',
      ])
    },
    createTime(createElement) {
      if (this.timeslot[this.field.name]) {
        return createElement('div', [
          createElement('div', [
            getFormattedTzDate(this.timeslot[this.field.name], 'DD.MM.YY'),
          ]),
          createElement('div', [
            getFormattedTzDate(this.timeslot[this.field.name], GET_TIME),
          ]),
        ])
      }

      return false
    },
    createStatus(createElement) {
      const statusTypeIcon =
        this.timeslot.truck_status === 'parked_far'
          ? 'el-icon-success'
          : 'el-icon-warning'

      return createElement(
        'div',
        { class: [this.field.name, this.timeslot[this.field.name]] },
        [
          createElement('i', { class: statusTypeIcon }),
          createElement('span', [this.timeslot.truck_status_text]),
        ],
      )
    },
  },
  render(createElement) {
    return this[this.field.function](createElement)
  },
}
</script>
