var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.queueData
    ? _c(
        "section",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "queue",
        },
        [
          _c("TerminalBackButton", {
            staticClass: "queue__button",
            on: {
              clicked: function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
            },
          }),
          _c("div", { staticClass: "queue__title" }, [
            _vm._v("Текущая очередь"),
          ]),
          _c(
            "div",
            { staticClass: "queue__total" },
            _vm._l(_vm.queueHeaderFields, function (item) {
              return _c("div", { key: item.name }, [
                _c("span", { staticClass: "queue__total-label" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
                _c("span", { staticClass: "queue__total-value" }, [
                  _vm._v(" " + _vm._s(_vm.queueData[item.name])),
                ]),
              ])
            }),
            0
          ),
          _c(
            "el-row",
            { staticClass: "queue__headers" },
            _vm._l(_vm.queueTableFields, function (title) {
              return _c(
                "el-col",
                {
                  key: title.name,
                  staticClass: "queue__headers-item",
                  attrs: { span: title.width },
                },
                [_vm._v(" " + _vm._s(title.label) + " ")]
              )
            }),
            1
          ),
          _vm._l(_vm.queueData.list, function (timeslot) {
            return _c(
              "el-row",
              {
                key: timeslot.id,
                class: [
                  "queue__timeslot",
                  timeslot.truck_status,
                  { current: _vm.isCurrent(timeslot) },
                ],
              },
              _vm._l(_vm.queueTableFields, function (title) {
                return _c(
                  "el-col",
                  {
                    key: title.name,
                    class: ["queue__timeslot-item", title.name],
                    attrs: { span: title.width },
                  },
                  [
                    _c("TableField", {
                      attrs: { field: title, timeslot: timeslot },
                    }),
                  ],
                  1
                )
              }),
              1
            )
          }),
          _c("el-pagination", {
            staticClass: "custom-pagination",
            attrs: {
              background: "",
              "hide-on-single-page": true,
              "page-count": _vm.pagination.pageCount,
              "pager-count": 5,
              "current-page": _vm.pagination.page,
              layout: "prev, pager, next",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.pagination, "page", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.pagination, "page", $event)
              },
              "current-change": _vm.changePage,
            },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }